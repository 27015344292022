/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Some introduction text. Lists out all the headings from h1 to h6. Markdown link handling for relative and absolute URLs. Easy to customise."), "\n", React.createElement(_components.h1, null, "Heading H1"), "\n", React.createElement(_components.p, null, "Heading 1 text"), "\n", React.createElement(_components.h2, null, "Heading H2"), "\n", React.createElement(_components.p, null, "Heading 2 text"), "\n", React.createElement(_components.h3, null, "Heading H3"), "\n", React.createElement(_components.p, null, "Heading 3 text"), "\n", React.createElement(_components.h4, null, "Heading H4"), "\n", React.createElement(_components.p, null, "Heading 4 text"), "\n", React.createElement(_components.h5, null, "Heading H5"), "\n", React.createElement(_components.p, null, "Heading 5 text"), "\n", React.createElement(_components.h6, null, "Heading H6"), "\n", React.createElement(_components.p, null, "Heading 6 text"), "\n", React.createElement(_components.h2, null, "Lists"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Item 1"), "\n", React.createElement(_components.li, null, "Item 2"), "\n", React.createElement(_components.li, null, "Item 3"), "\n", React.createElement(_components.li, null, "Item 4"), "\n", React.createElement(_components.li, null, "Item 5"), "\n"), "\n", React.createElement(_components.h2, null, "Links"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Relative: ", React.createElement(_components.a, {
    href: "/codeblock"
  }, "Codeblock")), "\n", React.createElement(_components.li, null, "Absolute: ", React.createElement(_components.a, {
    href: "https://learn.hasura.io/graphql/react"
  }, "Demo")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
