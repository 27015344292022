import GATSBY_COMPILED_MDX from "/working_dir/content/index.mdx";
import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import {MDXProvider} from "@mdx-js/react";
import {Layout, Link} from '$components';
import NextPrevious from '../components/NextPrevious';
import config from '../../config';
import {Edit, StyledHeading, StyledMainWrapper} from '../components/styles/Docs';
const forcedNavOrder = config.sidebar.forcedNavOrder;
const MdxDoc = ({children, location, data}) => {
  if (!data) {
    return children;
  }
  const {allMdx, mdx, site: {siteMetadata: {docsLocation, title}}} = data;
  const githubIcon = require('../components/images/github.svg').default;
  const navItems = allMdx.edges.map(({node}) => node.fields.slug).filter(slug => slug !== '/').sort().reduce((acc, cur) => {
    if (forcedNavOrder.find(url => url === cur)) {
      return {
        ...acc,
        [cur]: [cur]
      };
    }
    let prefix = cur.split('/')[1];
    if (config.gatsby && config.gatsby.trailingSlash) {
      prefix = prefix + '/';
    }
    if (prefix && forcedNavOrder.find(url => url === `/${prefix}`)) {
      return {
        ...acc,
        [`/${prefix}`]: [...acc[`/${prefix}`], cur]
      };
    } else {
      return {
        ...acc,
        items: [...acc.items, cur]
      };
    }
  }, {
    items: []
  });
  const nav = forcedNavOrder.reduce((acc, cur) => {
    return acc.concat(navItems[cur]);
  }, []).concat(navItems.items).map(slug => {
    if (slug) {
      const {node} = allMdx.edges.find(({node}) => node.fields.slug === slug);
      return {
        title: node.fields.title,
        url: node.fields.slug
      };
    }
  });
  const metaTitle = mdx.frontmatter.metaTitle;
  const metaDescription = mdx.frontmatter.metaDescription;
  let canonicalUrl = config.gatsby.siteUrl;
  canonicalUrl = config.gatsby.pathPrefix !== '/' ? canonicalUrl + config.gatsby.pathPrefix : canonicalUrl;
  canonicalUrl = canonicalUrl + mdx.fields.slug;
  return React.createElement(Layout, {
    location: location
  }, React.createElement(Helmet, null, metaTitle ? React.createElement("title", null, metaTitle) : null, metaTitle ? React.createElement("meta", {
    name: "title",
    content: metaTitle
  }) : null, metaDescription ? React.createElement("meta", {
    name: "description",
    content: metaDescription
  }) : null, metaTitle ? React.createElement("meta", {
    property: "og:title",
    content: metaTitle
  }) : null, metaDescription ? React.createElement("meta", {
    property: "og:description",
    content: metaDescription
  }) : null, metaTitle ? React.createElement("meta", {
    property: "twitter:title",
    content: metaTitle
  }) : null, metaDescription ? React.createElement("meta", {
    property: "twitter:description",
    content: metaDescription
  }) : null, React.createElement("link", {
    rel: "canonical",
    href: canonicalUrl
  })), React.createElement("div", {
    className: 'titleWrapper'
  }, React.createElement(StyledHeading, null, mdx.fields.title), React.createElement(Edit, {
    className: 'mobileView'
  }, docsLocation && React.createElement(Link, {
    className: 'gitBtn',
    to: `${docsLocation}/${mdx.parent.relativePath}`
  }, React.createElement("img", {
    src: githubIcon,
    alt: 'Github logo'
  }), " Edit on GitHub"))), React.createElement(StyledMainWrapper, null, children), React.createElement("div", {
    className: 'addPaddTopBottom'
  }, React.createElement(NextPrevious, {
    mdx: mdx,
    nav: nav
  })));
};
MdxDoc
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxDoc, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        metaDescription
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
